// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";

// import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
require("jquery");

import "../stylesheets/application";

Rails.start();
// Turbolinks.start();
ActiveStorage.start();

global.toastr = require("toastr");

global.Rails = Rails;

require("trix");
require("@rails/actiontext");

// import "chartkick/chart.js";
import "chartkick/highcharts";
require("@nathanvda/cocoon");

import "daterangepicker";

var moment = require("moment");
global.moment = moment;
window.moment = moment;
